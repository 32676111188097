<template>
  <div class="activityDialog">
    <el-dialog top="3%" width="50%" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="微信昵称">
              <el-input v-model="form.nickName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名">
              <el-input v-model="form.realName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信头像">
              <el-input v-model="form.headUrl" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别">
              <el-radio-group v-model="form.sex">
                <el-radio label="0">男</el-radio>
                <el-radio label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄">
              <el-input v-model="form.age"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系手机">
              <el-input v-model="form.phone" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身高(cm)">
              <el-input v-model="form.stature"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重(kg)">
              <el-input v-model="form.weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="openID">
              <el-input v-model="form.openid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邀请人">
              <el-select v-model="form.yqrId" placeholder="请选择邀请人" style="width: 100%">
                <el-option :label="item.label" :value="item.value" v-for="item in userList" :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input type="textarea" :rows="3" v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增用户",
      dialogImageUrl: "",
      dialogVisible: false,
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      form: {
        createTime: "", //创建时间
        openid: "", //微信ID
        nickName: "", //微信昵称
        headUrl: "", //微信头像
        sex: "0", //性别
        realName: "", //真实姓名
        phone: "", //联系手机
        stature: "", //身高
        weight: "", //体重
        remark: "", //简介
        age: "", //年龄
        yqrId: "",//邀请人
      },
      userList: [],//用户数组
    };
  },
  components: {},
  methods: {
    async submitData() {
      let params = { ...this.form };
      params.createTime = new Date().getTime();
      console.log(params);
      let url = "";
      if (params.id) {
        //修改
        url = "api/updateUser";
      } else {
        url = "api/addUser";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增用户";
      this.form = {};
    },
    getInfo(id) {
      this.dialogTitle = "编辑用户";
      this.getAjax("api/getUserDetails?id=" + id, {}, "get").then((res) => {
        this.form = res.data.info;
        this.visible = true;
        this.$emit("search");
      });
    },
  },
  mounted() {
    //获取所有用户
    this.getAjax("api/allUser", {}, "get").then((res) => {
      this.userList = res.data.list.map((e) => {
        return {
          label: e.nickName,
          value: e.id,
        };
      });
    });

  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
