<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="headUrl" label="头像" width="220">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <img
                style="width: 50px; height: 50px; border-radius: 100%"
                :src="scope.row.headUrl || require('@/assets/images/1.png')"
                alt=""
              />
              <div style="flex: 1">
                <p>{{ scope.row.nickName }}</p>
                <p>{{ scope.row.sex | sex }}</p>
                <p>{{ scope.row.realName }}</p>
                <p>{{ scope.row.phone }}</p>
              </div>
            </div>
          </template></el-table-column
        >
        <el-table-column prop="createTime" label="创建时间" width="160">
          <template slot-scope="scope">{{
            scope.row.createTime | formatTime("YMDHMS")
          }}</template>
        </el-table-column>

        <el-table-column prop="remark" label="信息">
          <template slot-scope="scope">
            <div>
              <p>待提现金额：{{ scope.row.dtxAccount }}</p>
              <p>可提现金额：{{ scope.row.account }}</p>
              <p>积分：{{ scope.row.jifen }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="power" label="是否见证官">
          <template slot-scope="scope">
            <span :class="scope.row.power === 1 ? 'c_red' : 'c_grey'">
              {{ scope.row.power === 1 ? "是" : "否" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="220">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.power === 0"
              type="text"
              size="small"
              @click="setJzg(1, scope.row)"
              >设置见证官</el-button
            >
            <el-button
              v-if="scope.row.power === 1"
              type="text"
              size="small"
              @click="setJzg(0, scope.row)"
              >取消见证官</el-button
            >
            <el-button type="text" size="small" @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button @click="deleteData(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      page: {
        page: 1,
        pageSize: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-select",
          fieldName: "sex",
          data: [
            { label: "男", value: 0 },
            { label: "女", value: 1 },
          ],
          attrs: {
            placeholder: "性别",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "nickName",
          attrs: {
            placeholder: "微信名",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "realName",
          attrs: {
            placeholder: "真实名称",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "phone",
          attrs: {
            placeholder: "手机号码",
          },
        },
      ],
    };
  },
  methods: {
    setJzg(power, opt) {
      this.getAjax(
        "api/user/setJzg",
        {
          power,
          openid: opt.openid,
        },
        "post"
      ).then((res) => {
        this.initPage();
        this.$message.success('设置成功');
      });
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.page.page = 1;
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/userList",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        res.data.list.records.forEach((e) => {
          e.account = (parseFloat(e.account) / 100).toFixed(2);
          e.dtxAccount = (parseFloat(e.dtxAccount) / 100).toFixed(2);
        });
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/delUser", { id: item.id }, "post").then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  .leftBox {
    width: 10%;
    min-width: 130px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
